import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Title } from '../components/Text';
import { sprinkles } from '../sprinkles.css';

const NotFoundPage = () => {
	const { t } = useTranslation('NotFoundPage');
	return (
		<Layout>
			<Section
				width="auto"
				justify="center"
				direction="vertical"
				className={sprinkles({ height: 'fill' })}
				gap="larger"
			>
				<Title align="center" level="title">
					404
				</Title>
				<Link to="/" className={ButtonRecipe({ size: 'large', variant: 'primary' })}>
					{t('BackToHome')}
				</Link>
			</Section>
		</Layout>
	);
};

export const Head = () => {
	return <Seo title="404" description="Page not found" />;
};

export default NotFoundPage;
